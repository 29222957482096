export default {
  yes: "Oui",
  no: "Non",
  and: "et",
  error:
    "Une erreur s’est produite. Veuillez remplir les informations à nouveau ou contacter le support",
  successUpdate: "Correctement mis à jour",
  successCreate: "Correctement créé",
  successDelete: "Correctement supprimé",
  fieldRequired: "Ce champ est obligatoire",
  fieldRequiredAndMinChar:
    "Ce champ est obligatoire et doit contenir au minimum {0} caractères",
  fieldRequiredAndMinItems:
    "Ce champ est obligatoire et doit contenir au minimum {0} éléments",
  customer: {
    errorCreate: "Erreur inattendue lors de la création du client",
    alreadyExist:
      "Le client existe déjà. Vos données de création n'ont pas été pris en compte. SI vous souhaitez les changer merci de les éditer sur cette page.",
  },
  vendor: {
    errorCreate: "Erreur inattendue lors de la création du vendeur",
    alreadyExist:
      "Le vendeur existe déjà. Vous ne pouvez ajouter un vendeur déjà ajouter par une autre entreprise",
  },
  deposit: {
    errorCreate: "Erreur inattendue lors de la création du dépôt",
    alreadyExist:
      "Un dépôt avec le même nom existe déjà. Si cette erreur apparait avec un import merci de renommer la feuille de votre fichier excel.",
    alreadyExistCustomer:
      "Un dépôt avec le même nom existe déjà. Vous ne pouvez pas déposer 2 dépôts du même genre le même jour.",
    wantDeposit:
      "Action bien prise en compte. Un administrateur vous contactera pour valider votre demande dans les 48h et vous attribura le dépôt.",
    notAssigned: "Ce dépôt n'est pas encore assigné.",
    adminNotesUpdated: "Notes mises à jour",
  },
  NEW_WITH_TICKET: "Neuf avec étiquette",
  NEW_WITHOUT_TICKET: "Neuf sans étiquette",
  VERY_GOOD: "Très bon état",
  GOOD: "Bon état",
  SATISFYING: "Satisfaisant",
  REFUSED: "Refusé",
  RESTITUED: "Restitué",
  SELL: "Vendu",
  PAYED: "Payé",
  FACTURED: "Facturé",
  ONLINE: "En ligne",
  OFFLINE: "Hors ligne",
  RESERVED: "Reservé",
  product: {
    brand: "une nouvelle marque",
    category: "une nouvelle catégorie de vêtements",
    validationText: "Vous êtes sur le point de créer {validationItem}: {item}",
    NoComparable:
      "Il n'y a pas de produit comparable dans notre base. Cliquez sur le bouton actions une fois le nom du produit spécifié, pour lancer une recherche de prix vinted.",
    errorUpdate: "Une erreur à eu lieu pendant la mise à jour du produit",
    noRateSlide: "Pas de commission pour ce prix",
    nameNotFillForSearch:
      "Merci de renseigner un titre du produit pour lancer la recherche",
    itemreadonly:
      "Les produits importés “vendu” depuis un import ne sont pas modifiables",
    itemreadonlyFactured: "Les produits facturés ne sont pas modifiables",
    itemreadonlyCustomer: "Vous ne pouvez pas modifier l'inventaire",
    qualityAndPriceTooLow:
      "L'état et le prix de l’article ne permet pas de le commercialiser.  Veuillez contacter le service support Dresskare.",
    priceTooLow:
      "Le prix de l’article ne permet pas de le commercialiser. Veuillez contacter le service support Dresskare.",
    qualityTooLow:
      "L'état de l’article ne permet pas de le commercialiser.  Veuillez contacter le service support Dresskare.",
  },
  size: "taille",
  waitFirstAnswer:
    "En attente de la première réponse du serveur pour savoir le nombre à exporter",
  elementFetched: "éléments recupérés",
  waitForDownload:
    "Tous les éléments ont été récupéré. En attente de la création du fichier d'export.",
  invoice: {
    PAYED: "Payée",
    NOT_PAYED: "Non payée",
    PAYEMENT_CONFIRMED: "Paiement confirmé",
    MONTHLY_VENDOR: "Prestation de services",
    DEPOSIT_CUSTOMER: "Facture d'achat vêtements",
    DRESSKARE_INVOICE: "Abonnement DressKare",
    DRESSKARE_FORMATION: "Formation DressKare",
    DRESSKARE_COMMISSION: "Commission Dresskare",
    CLOTHES_SELL: "Vente(s) de vêtements",
    CLOTHES_BUY: "Facture d'achat dépôt",
  },
  LOW: "Entrée de gamme/Fast fashion",
  AVERAGE: "Milieu de gamme",
  HIGH_AND_LUXURY: "Haut de gamme et luxe",
  LOWWithDetails: "Entrée de gamme/Fast fashion (Zara, Mango, H&M…)",
  AVERAGEWithDetails:
    "Milieu de gamme (Sandro, The Kooples, Sézane, Claudie Pierlot, Levi’s, Vans)",
  HIGH_AND_LUXURYWithDetails:
    "Haut de gamme et luxe (Gucci, Dior, Louis Vitton…)",
  NOT_SETWithDetails: "Non spécifié",
  LOWSmall: "Entrée de gamme",
  AVERAGESmall: "Milieu de gamme",
  HIGH_AND_LUXURYSmall: "Haut de gamme",
  HIGHSmall: "Haut de gamme",
  NOT_SETSmall: "Non spécifié",
  HIGH: "Haut de gamme",
  LUXURY: "Luxe",
  VINTAGE: "Vintage",
  NOT_SET: "Non spécifié",
  FACEBOOK: "Facebook",
  INSTAGRAM: "Instagram",
  JOB_BOARD: "Annonce d'emplois",
  VINTED: "Vinted",
  WORD_OF_MOUTH: "Bouche à oreille",
  DRESSKARE_WEBSITE: "Notre site DressKare",
  DRESSKARE_BLOG: "Notre blog",
  SPONSORING: "Parrainage",
  PHONE_CALL: "Appel",
  EMAIL: "Mail",
  PHONE_MESSAGE: "SMS",
  WHATS_APP: "WhatsApp",
  depositStatusUpdate: {
    confirmChangeDepositStatus:
      "Confirmez vous vouloir changer le statut du dépot ?",
    abandonedMessage: "Si vous confirmez vous allez perdre l'accès à ce dépôt.",
    restituedMessage:
      "Si vous confirmez votre restitution, cela indique que vous n'êtes plus en possession du dépôt. Cela changera tout les statuts des articles non facturés en hors ligne et vous ne verrez plus le dépôt dans votre liste. Tous les profits resteront associés à votre compte et si certains produits sont en attente de facturation, ils seront facturés fin de mois.",
    errorUpdateStatus:
      "Une erreur est survenue pendant la mise à jour du dépot. Merci de contacter le support",
  },
  incomplete: "Incomplet",
  incomplete_expired: "Incomplet & expiré",
  trialing: "En essai",
  active: "Actif",
  past_due: "Paiement en retard",
  canceled: "Annulé",
  unpaid: "Non payé",
  FREEMIUM: "Freemium",
  ESSENTIAL: "Essentiel",
  PRO: "Pro",
  PREMIUM: "Premium",
  COMMISSION: "Commission",
  FORMATION: "Formation",
  GIRL: "Fille",
  BOY: "Garçon",
  MIXTE: "Mixte",
  Filter: "Filtrer",
  copyToClipboardErrorCopy:
    "Impossible de copier. Merci de regarder les permissions d'accès au presse papier de l'app.",
  needFavoriteAnnouncement: "Pas d'annonce favorite.",
  errorFetchingAnnouncement:
    "Erreur pendant la récupération de l'annonce favorite. Si le problème persiste merci de contacter le support.",
  NO_PUBLISHERS: "Aucun publishers sélectionnés",
  NO_PRODUCTS: "Auncun produits sélectionnés",
  PRODUCT_NOT_COMPLETE:
    "Le produit {product} n'est pas complet. {attr} est manquant",
  publishSuccess: "Produits publiés avec succèss",
  publishUnknownError:
    "Une erreur inconnue est survenue merci de contacter le support en précisant le dépôt, la référence des produits sélectionnés et la platforme souhaité pour la publication",
};
