// @generated by protoc-gen-es v1.7.2 with parameter "target=js+dts"
// @generated from file services/dresskare_back/vendor.proto (package dresskareback.vendor, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Struct } from "@bufbuild/protobuf";

/**
 * @generated from message dresskareback.vendor.AnnouncementDestroyRequest
 */
export const AnnouncementDestroyRequest = proto3.makeMessageType(
  "dresskareback.vendor.AnnouncementDestroyRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.vendor.AnnouncementListRequest
 */
export const AnnouncementListRequest = proto3.makeMessageType(
  "dresskareback.vendor.AnnouncementListRequest",
  [],
);

/**
 * @generated from message dresskareback.vendor.AnnouncementListResponse
 */
export const AnnouncementListResponse = proto3.makeMessageType(
  "dresskareback.vendor.AnnouncementListResponse",
  () => [
    { no: 1, name: "results", kind: "message", T: AnnouncementResponse, repeated: true },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message dresskareback.vendor.AnnouncementPartialUpdateRequest
 */
export const AnnouncementPartialUpdateRequest = proto3.makeMessageType(
  "dresskareback.vendor.AnnouncementPartialUpdateRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 6, name: "vendor", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "company", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "_partial_update_fields", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message dresskareback.vendor.AnnouncementRequest
 */
export const AnnouncementRequest = proto3.makeMessageType(
  "dresskareback.vendor.AnnouncementRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 6, name: "vendor", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "company", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.vendor.AnnouncementResponse
 */
export const AnnouncementResponse = proto3.makeMessageType(
  "dresskareback.vendor.AnnouncementResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 6, name: "vendor", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "company", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "is_default", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.vendor.AnnouncementRetrieveRequest
 */
export const AnnouncementRetrieveRequest = proto3.makeMessageType(
  "dresskareback.vendor.AnnouncementRetrieveRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.vendor.CompanyDestroyRequest
 */
export const CompanyDestroyRequest = proto3.makeMessageType(
  "dresskareback.vendor.CompanyDestroyRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.vendor.CompanyListRequest
 */
export const CompanyListRequest = proto3.makeMessageType(
  "dresskareback.vendor.CompanyListRequest",
  [],
);

/**
 * @generated from message dresskareback.vendor.CompanyListResponse
 */
export const CompanyListResponse = proto3.makeMessageType(
  "dresskareback.vendor.CompanyListResponse",
  () => [
    { no: 1, name: "results", kind: "message", T: CompanyResponse, repeated: true },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message dresskareback.vendor.CompanyPartialUpdateRequest
 */
export const CompanyPartialUpdateRequest = proto3.makeMessageType(
  "dresskareback.vendor.CompanyPartialUpdateRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "siret", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "proof_registration", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "website_link", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "tax_eligible", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 8, name: "negociated_pourcentage_on_price", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 9, name: "_partial_update_fields", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message dresskareback.vendor.CompanyRequest
 */
export const CompanyRequest = proto3.makeMessageType(
  "dresskareback.vendor.CompanyRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "siret", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "proof_registration", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "website_link", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "tax_eligible", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 8, name: "negociated_pourcentage_on_price", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.vendor.CompanyResponse
 */
export const CompanyResponse = proto3.makeMessageType(
  "dresskareback.vendor.CompanyResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "siret", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "proof_registration", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "website_link", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "tax_eligible", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 8, name: "negociated_pourcentage_on_price", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.vendor.CompanyRetrieveRequest
 */
export const CompanyRetrieveRequest = proto3.makeMessageType(
  "dresskareback.vendor.CompanyRetrieveRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.vendor.CustomerAcceptLegalTermRequest
 */
export const CustomerAcceptLegalTermRequest = proto3.makeMessageType(
  "dresskareback.vendor.CustomerAcceptLegalTermRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.vendor.CustomerAcceptLegalTermResponse
 */
export const CustomerAcceptLegalTermResponse = proto3.makeMessageType(
  "dresskareback.vendor.CustomerAcceptLegalTermResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "accept_legal_term", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.vendor.CustomerCheckIfCustomerEmailExistRequest
 */
export const CustomerCheckIfCustomerEmailExistRequest = proto3.makeMessageType(
  "dresskareback.vendor.CustomerCheckIfCustomerEmailExistRequest",
  () => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.vendor.CustomerCheckIfCustomerEmailExistResponse
 */
export const CustomerCheckIfCustomerEmailExistResponse = proto3.makeMessageType(
  "dresskareback.vendor.CustomerCheckIfCustomerEmailExistResponse",
  () => [
    { no: 1, name: "exist", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message dresskareback.vendor.CustomerGetUuidByEmailRequest
 */
export const CustomerGetUuidByEmailRequest = proto3.makeMessageType(
  "dresskareback.vendor.CustomerGetUuidByEmailRequest",
  () => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.vendor.CustomerGetUuidByEmailResponse
 */
export const CustomerGetUuidByEmailResponse = proto3.makeMessageType(
  "dresskareback.vendor.CustomerGetUuidByEmailResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.vendor.CustomerImportDataResponse
 */
export const CustomerImportDataResponse = proto3.makeMessageType(
  "dresskareback.vendor.CustomerImportDataResponse",
  () => [
    { no: 1, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "import_result", kind: "message", T: Struct },
  ],
);

/**
 * @generated from message dresskareback.vendor.CustomerLessPermissionRequest
 */
export const CustomerLessPermissionRequest = proto3.makeMessageType(
  "dresskareback.vendor.CustomerLessPermissionRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "rib", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "age_range", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 7, name: "accept_home_take_away", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 8, name: "accept_send_postal", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 9, name: "prefered_contact_way", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.vendor.CustomerListRequest
 */
export const CustomerListRequest = proto3.makeMessageType(
  "dresskareback.vendor.CustomerListRequest",
  [],
);

/**
 * @generated from message dresskareback.vendor.CustomerListResponse
 */
export const CustomerListResponse = proto3.makeMessageType(
  "dresskareback.vendor.CustomerListResponse",
  () => [
    { no: 1, name: "results", kind: "message", T: CustomerResponse, repeated: true },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message dresskareback.vendor.CustomerRequest
 */
export const CustomerRequest = proto3.makeMessageType(
  "dresskareback.vendor.CustomerRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "rib", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 11, name: "vendors", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 12, name: "is_sourced_by_dresskare", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 14, name: "age_range", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 15, name: "accept_home_take_away", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 16, name: "accept_send_postal", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 17, name: "register_to_customer_newsletter", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 18, name: "accept_contact_dresskare", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 19, name: "know_dresskare", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 20, name: "accepted_legal_term_at", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 21, name: "prefered_contact_way", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.vendor.CustomerResponse
 */
export const CustomerResponse = proto3.makeMessageType(
  "dresskareback.vendor.CustomerResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "keycloak_user_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "deposit_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 8, name: "product_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 9, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 11, name: "rib", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 12, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 13, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 14, name: "vendors", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 15, name: "vendors_obj", kind: "message", T: SmallVendorResponse, repeated: true },
    { no: 16, name: "is_already_existing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "deposit_vendors_obj", kind: "message", T: SmallVendorResponse, repeated: true },
    { no: 18, name: "is_sourced_by_dresskare", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 20, name: "age_range", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 21, name: "accept_home_take_away", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 22, name: "accept_send_postal", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 23, name: "register_to_customer_newsletter", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 24, name: "accept_contact_dresskare", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 25, name: "know_dresskare", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 26, name: "legal_terms_updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 27, name: "accepted_legal_term_at", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 28, name: "prefered_contact_way", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.vendor.CustomerRetrieveByKeycloakUuidRequest
 */
export const CustomerRetrieveByKeycloakUuidRequest = proto3.makeMessageType(
  "dresskareback.vendor.CustomerRetrieveByKeycloakUuidRequest",
  () => [
    { no: 1, name: "keycloak_user_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.vendor.CustomerRetrieveRequest
 */
export const CustomerRetrieveRequest = proto3.makeMessageType(
  "dresskareback.vendor.CustomerRetrieveRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.vendor.CustomerSetSentSetailsRequest
 */
export const CustomerSetSentSetailsRequest = proto3.makeMessageType(
  "dresskareback.vendor.CustomerSetSentSetailsRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "accept_send_postal", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "accept_home_take_away", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message dresskareback.vendor.CustomerSetSentSetailsResponse
 */
export const CustomerSetSentSetailsResponse = proto3.makeMessageType(
  "dresskareback.vendor.CustomerSetSentSetailsResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "accept_send_postal", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "accept_home_take_away", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message dresskareback.vendor.ImportCustomerRequest
 */
export const ImportCustomerRequest = proto3.makeMessageType(
  "dresskareback.vendor.ImportCustomerRequest",
  () => [
    { no: 1, name: "sheet_file", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.vendor.RateSlideDestroyRequest
 */
export const RateSlideDestroyRequest = proto3.makeMessageType(
  "dresskareback.vendor.RateSlideDestroyRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.vendor.RateSlideListRequest
 */
export const RateSlideListRequest = proto3.makeMessageType(
  "dresskareback.vendor.RateSlideListRequest",
  [],
);

/**
 * @generated from message dresskareback.vendor.RateSlideListResponse
 */
export const RateSlideListResponse = proto3.makeMessageType(
  "dresskareback.vendor.RateSlideListResponse",
  () => [
    { no: 1, name: "results", kind: "message", T: RateSlideResponse, repeated: true },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message dresskareback.vendor.RateSlidePartialUpdateRequest
 */
export const RateSlidePartialUpdateRequest = proto3.makeMessageType(
  "dresskareback.vendor.RateSlidePartialUpdateRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "price_start", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "price_end", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "commission", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 5, name: "vendor", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "deposit", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "company", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "_partial_update_fields", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: "dresskare_commission", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.vendor.RateSlideRequest
 */
export const RateSlideRequest = proto3.makeMessageType(
  "dresskareback.vendor.RateSlideRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "price_start", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "price_end", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "commission", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 5, name: "vendor", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "deposit", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "company", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "dresskare_commission", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.vendor.RateSlideResponse
 */
export const RateSlideResponse = proto3.makeMessageType(
  "dresskareback.vendor.RateSlideResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "price_start", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "price_end", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "commission", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 5, name: "vendor", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "deposit", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "company", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "dresskare_commission", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.vendor.RateSlideRetrieveRequest
 */
export const RateSlideRetrieveRequest = proto3.makeMessageType(
  "dresskareback.vendor.RateSlideRetrieveRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.vendor.SmallCustomerResponse
 */
export const SmallCustomerResponse = proto3.makeMessageType(
  "dresskareback.vendor.SmallCustomerResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "is_sourced_by_dresskare", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.vendor.SmallVendorResponse
 */
export const SmallVendorResponse = proto3.makeMessageType(
  "dresskareback.vendor.SmallVendorResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "vinted_profile", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.vendor.SubscriptionResponse
 */
export const SubscriptionResponse = proto3.makeMessageType(
  "dresskareback.vendor.SubscriptionResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "plan_interval", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "plan_amount", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 7, name: "current_period_end", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "discount_coupon_percent_off", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 9, name: "discount_coupon_valid", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.vendor.VendorCreateRequest
 */
export const VendorCreateRequest = proto3.makeMessageType(
  "dresskareback.vendor.VendorCreateRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "company", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "negociated_pourcentage_on_price", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 11, name: "favorite_delivery_place", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 12, name: "profil_picture", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 13, name: "is_company_admin", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "favorite_announcement", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 15, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 17, name: "identity_card", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 18, name: "vinted_profile", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 19, name: "is_self_employed", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 20, name: "receive_new_deposit_status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 21, name: "instagram_or_website", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 22, name: "know_dresskare", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 23, name: "accepted_legal_term_at", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 24, name: "prefered_contact_way", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 25, name: "preferred_size", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 26, name: "deposit_gender_favorite", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 27, name: "extension_free_trial_start_datetime", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.vendor.VendorCreateResponse
 */
export const VendorCreateResponse = proto3.makeMessageType(
  "dresskareback.vendor.VendorCreateResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "keycloak_user_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "company", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 11, name: "negociated_pourcentage_on_price", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 12, name: "favorite_delivery_place", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 13, name: "profil_picture", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 14, name: "is_company_admin", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "favorite_announcement", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 16, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 18, name: "deposits", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 20, name: "identity_card", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 21, name: "deposit_customers_obj", kind: "message", T: SmallCustomerResponse, repeated: true },
    { no: 22, name: "vinted_profile", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 23, name: "company_obj", kind: "message", T: CompanyResponse, opt: true },
    { no: 24, name: "is_self_employed", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 25, name: "is_temporarly_blocked_for_new_deposit", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 27, name: "sponsor_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 28, name: "receive_new_deposit_status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 29, name: "instagram_or_website", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 30, name: "legal_terms_updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 31, name: "know_dresskare", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 32, name: "accepted_legal_term_at", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 33, name: "prefered_contact_way", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 34, name: "preferred_size", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 35, name: "subscription", kind: "message", T: SubscriptionResponse, opt: true },
    { no: 36, name: "product_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 37, name: "deposit_gender_favorite", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 38, name: "extension_free_trial_start_datetime", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 39, name: "has_vinted_api_access", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message dresskareback.vendor.VendorGetLastActivityRequest
 */
export const VendorGetLastActivityRequest = proto3.makeMessageType(
  "dresskareback.vendor.VendorGetLastActivityRequest",
  () => [
    { no: 1, name: "vendor_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.vendor.VendorGetNameRequest
 */
export const VendorGetNameRequest = proto3.makeMessageType(
  "dresskareback.vendor.VendorGetNameRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.vendor.VendorLastActivityResponse
 */
export const VendorLastActivityResponse = proto3.makeMessageType(
  "dresskareback.vendor.VendorLastActivityResponse",
  () => [
    { no: 1, name: "last_activity", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.vendor.VendorListRequest
 */
export const VendorListRequest = proto3.makeMessageType(
  "dresskareback.vendor.VendorListRequest",
  [],
);

/**
 * @generated from message dresskareback.vendor.VendorListResponse
 */
export const VendorListResponse = proto3.makeMessageType(
  "dresskareback.vendor.VendorListResponse",
  () => [
    { no: 1, name: "results", kind: "message", T: VendorResponse, repeated: true },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message dresskareback.vendor.VendorPartialUpdateRequest
 */
export const VendorPartialUpdateRequest = proto3.makeMessageType(
  "dresskareback.vendor.VendorPartialUpdateRequest",
  () => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 9, name: "accepted_legal_term_at", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "know_dresskare", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 11, name: "prefered_contact_way", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 12, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 13, name: "company", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 14, name: "negociated_pourcentage_on_price", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 15, name: "favorite_delivery_place", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 16, name: "profil_picture", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 17, name: "favorite_announcement", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 18, name: "identity_card", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 19, name: "vinted_profile", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 20, name: "is_self_employed", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 21, name: "receive_new_deposit_status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 22, name: "instagram_or_website", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 23, name: "preferred_size", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 24, name: "_partial_update_fields", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 25, name: "deposit_gender_favorite", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 26, name: "extension_free_trial_start_datetime", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.vendor.VendorRequest
 */
export const VendorRequest = proto3.makeMessageType(
  "dresskareback.vendor.VendorRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "company", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "negociated_pourcentage_on_price", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 11, name: "favorite_delivery_place", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 12, name: "profil_picture", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 13, name: "favorite_announcement", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 14, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 16, name: "identity_card", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 17, name: "vinted_profile", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 18, name: "is_self_employed", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 19, name: "receive_new_deposit_status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 20, name: "instagram_or_website", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 21, name: "know_dresskare", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 22, name: "accepted_legal_term_at", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 23, name: "prefered_contact_way", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 24, name: "preferred_size", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 25, name: "deposit_gender_favorite", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 26, name: "extension_free_trial_start_datetime", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.vendor.VendorResponse
 */
export const VendorResponse = proto3.makeMessageType(
  "dresskareback.vendor.VendorResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "keycloak_user_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "company", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 11, name: "negociated_pourcentage_on_price", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 12, name: "favorite_delivery_place", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 13, name: "profil_picture", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 14, name: "is_company_admin", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 15, name: "favorite_announcement", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 16, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 18, name: "deposits", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 20, name: "identity_card", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 21, name: "deposit_customers_obj", kind: "message", T: SmallCustomerResponse, repeated: true },
    { no: 22, name: "vinted_profile", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 23, name: "company_obj", kind: "message", T: CompanyResponse, opt: true },
    { no: 24, name: "is_self_employed", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 25, name: "is_temporarly_blocked_for_new_deposit", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 27, name: "sponsor_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 28, name: "receive_new_deposit_status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 29, name: "instagram_or_website", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 30, name: "legal_terms_updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 31, name: "know_dresskare", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 32, name: "accepted_legal_term_at", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 33, name: "prefered_contact_way", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 34, name: "preferred_size", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 35, name: "subscription", kind: "message", T: SubscriptionResponse, opt: true },
    { no: 36, name: "product_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 37, name: "deposit_gender_favorite", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 38, name: "extension_free_trial_start_datetime", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 39, name: "has_vinted_api_access", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message dresskareback.vendor.VendorRetrieveByKeycloakUuidRequest
 */
export const VendorRetrieveByKeycloakUuidRequest = proto3.makeMessageType(
  "dresskareback.vendor.VendorRetrieveByKeycloakUuidRequest",
  () => [
    { no: 1, name: "keycloak_user_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.vendor.VendorRetrieveRequest
 */
export const VendorRetrieveRequest = proto3.makeMessageType(
  "dresskareback.vendor.VendorRetrieveRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

