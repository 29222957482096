import { Model } from "@vuex-orm/core";
import Customer from "./Customer";
import { SUBSCRIPTION_TYPE } from "./Subscription";
// import Category from "./Category";
import Vendor from "./Vendor";
import RateSlide from "./RateSlide";
import store from "@/store";
import i18n from "@/setup/i18n";
import useSubscriptionHelper from "@/composables/useSubscriptionHelper";
import DepositImage from "./DepositImage";

import useDresskareGrpcClient from "@/composables/useDresskareGrpcClient";
import { DepositController } from "@dresskare/dresskare-grpc-api/connect/services/dresskare_back/product_connect";
import { DepositRequest } from "@dresskare/dresskare-grpc-api/connect/services/dresskare_back/product_pb";

const depositClient = useDresskareGrpcClient(DepositController);

const { checkInErrorIfSubscriptionUpdateNeeded } = useSubscriptionHelper();

export default class Deposit extends Model {
  static entity = "deposit";

  static primaryKey = "uuid";

  static _isSourcedByDresskare = null;
  static _hasDresskareCommission = null;

  static fields() {
    return {
      uuid: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      depositDatetime: this.attr(null),
      rateSlidesObjs: this.hasMany(RateSlide, "deposit"),
      vendor: this.attr(null),
      vendorObj: this.belongsTo(Vendor, "vendor", "uuid"),
      createdBy: this.attr(null),
      customer: this.attr(null),
      customerObj: this.belongsTo(Customer, "customer", "uuid"),
      defaultProductNumber: this.number(0),
      productNumberRange: this.attr(null),
      productNumber: this.number(0),
      status: this.string(""),
      gender: this.string(""),
      gamme: this.string(""),
      name: this.string(""),
      picturesLink: this.string(""),
      company: this.attr(null),
      isImported: this.boolean(false),
      defaultProductSize: this.string(""),
      defaultQuality: this.string(""),
      depositRecovery: this.string(""),
      customerPostalCode: this.string(""),
      description: this.string(""),
      forceDresskareCommission: this.boolean(false),
      publied: this.boolean(false),
      depositEndDatetime: this.attr(null),
      topFiveBrand: this.string(null).nullable(),
      luxuryBrand: this.string(null).nullable(),
      seasons: this.attr(null),
      isOkayToSendByPost: this.boolean(false),
      isOkayToSendByPostIsNull: this.boolean(false),
      depositPreparationStatus: this.string(""),
      depositSellingModeStatus: this.string(""),
      cashOfferStatus: this.string(""),
      cashOfferPrice: this.number(0),
      daysNumberBetweenDepositAssignAndInline: this.number(0),
      monthCa: this.number(0),
      vendorMonthCa: this.number(0),
      customerMonthCa: this.number(0),
      potentialCa: this.number(0),
      pourcentSell: this.number(0),
      categories: this.attr([]),
      customerAcceptHomeTakeAway: this.boolean(false),
      customerAcceptSendPostal: this.boolean(true),
      customerPreferedContactWay: this.string(),
      weight: this.string(),
      isCreatedByPublicForm: this.boolean(false),
      exceedLimit: this.boolean(false),
      _isSourcedByDresskare: this.attr(null),
      _hasDresskareCommission: this.attr(null),
      customerValidationResponse: this.string(null).nullable(),
      customerValidationComment: this.string(null).nullable(),
      // categoriesObjs: this.hasMany(Category, "deposit"),
      images: this.hasMany(DepositImage, "deposit"),
    };
  }

  get isSourcedByDresskare() {
    if (this._isSourcedByDresskare !== null) {
      return this._isSourcedByDresskare;
    }
    const instanceWithCustomer = this.$query()
      .with("customerObj")
      .where("uuid", this.uuid)
      .get()[0];
    this._isSourcedByDresskare =
      instanceWithCustomer.customerObj.isSourcedByDresskare;
    return this._isSourcedByDresskare;
  }

  get hasDresskareCommission() {
    if (this._hasDresskareCommission !== null) {
      return this._hasDresskareCommission;
    }
    const instanceWithVendor = this.$query()
      .with("vendorObj.subscription")
      .where("uuid", this.uuid)
      .get()[0];
    this._hasDresskareCommission =
      instanceWithVendor?.vendorObj?.subscription?.type ===
        SUBSCRIPTION_TYPE.COMMISSION ||
      instanceWithVendor.forceDresskareCommission;
    return this._hasDresskareCommission;
  }

  static getDeposits = async (
    filters,
    defaultFiltering = { status: getDefaultStatus() }
  ) => {
    // const depositsFromStore = this.all()

    // if (depositsFromStore.length) {
    //   return depositsFromStore
    // } else {
    try {
      let metadata = {};
      if (filters) {
        if (filters.ordering) {
          filters.ordering = filters.ordering.join(",");
        }
        filters = {
          ...defaultFiltering,
          ...filters,
        };
        metadata.filters = JSON.stringify(filters);
      } else {
        filters = defaultFiltering;
      }

      const response = await depositClient.list(undefined, {
        headers: metadata,
      });

      if (!response.results) {
        return null;
      }
      this.insertOrUpdate({ data: response.results });
      return response;
    } catch (error) {
      console.error("error:", error);
      return null;
    }
    // }
  };

  static create = async (form, isCustomer = false, raiseError = false) => {
    if (!form.depositRecovery) {
      form.depositRecovery = DEPOSIT_RECOVERY.NOT_SET;
    }

    try {
      const response = await depositClient.create(
        DepositRequest.fromJson(form, { ignoreUnknownFields: true }),
        {}
      );

      this.insertOrUpdate({ data: response });
      return response;
    } catch (error) {
      const isSubscriptionUpdateNeeded =
        checkInErrorIfSubscriptionUpdateNeeded(error);
      if (isSubscriptionUpdateNeeded) {
        return null;
      }
      console.error("error:", error);

      if (error.message.includes("UniqueCustomerGenderPerDay")) {
        if (isCustomer) {
          store.dispatch(
            "notifications/showErrorNotification",
            i18n.t("deposit.alreadyExistCustomer")
          );
        } else {
          store.dispatch(
            "notifications/showErrorNotification",
            i18n.t("deposit.alreadyExist")
          );
        }
      } else {
        store.dispatch(
          "notifications/showErrorNotification",
          i18n.t("deposit.errorCreate")
        );
      }
      if (raiseError) {
        throw error;
      }
      return null;
    }
  };

  static updateInDb = async (form) => {
    if (!form.depositRecovery) {
      form.depositRecovery = DEPOSIT_RECOVERY.NOT_SET;
    }

    const response = await depositClient.update(
      DepositRequest.fromJson(form, { ignoreUnknownFields: true }),
      {}
    );

    if (!response) {
      return null;
    }
    this.insertOrUpdate({ data: response });
    return response;
  };

  static getDepositByUuid = async (depositUuid) => {
    const depositFromStore = this.find(depositUuid);

    if (depositFromStore) {
      return depositFromStore;
    } else {
      try {
        const response = await depositClient.retrieve(
          { uuid: depositUuid },
          {}
        );

        if (!response) {
          return null;
        }
        this.insertOrUpdate({ data: response });
        return response;
      } catch (error) {
        console.error("error:", error);
        return null;
      }
    }
  };

  static destroy = async (depositUuid) => {
    await depositClient.destroy({ uuid: depositUuid }, {});
    this.delete(depositUuid);
  };

  static wantDeposit = async (depositUuid, vendorUuid) => {
    try {
      const response = await depositClient.wantDeposit(
        { uuid: depositUuid, vendorUuid: vendorUuid },
        {}
      );

      if (!response) {
        return null;
      }
      this.insertOrUpdate({ data: response });
      store.commit("notifications/setLastNotification", {
        timeout: 10000,
        position: "center",
        color: "success",
        text: i18n.t("deposit.wantDeposit"),
      });
      return response;
    } catch (error) {
      store.dispatch("notifications/showErrorNotification", i18n.t("error"));
      return null;
    }
  };

  static getAllFromProducts = async (products) => {
    let depositsUuid = products.reduce((acc, product) => {
      // console.log("deposit find: ", this.query().where("deposit", product.deposit).get())
      const depositAlreadyFetched = this.query()
        .where("uuid", product.deposit)
        .exists();
      if (!depositAlreadyFetched) {
        acc.add(product.deposit);
      }
      return acc;
    }, new Set());
    depositsUuid = Array.from(depositsUuid);
    if (!depositsUuid.length) {
      return;
    }
    return await this.getDeposits({ uuids: depositsUuid });
  };

  static getAdminNotes = async (depositUuid) => {
    try {
      const response = await depositClient.getAdminNotes(
        { uuid: depositUuid },
        {}
      );

      if (!response) {
        return null;
      }
      this.insertOrUpdate({ data: response });
      return response;
    } catch (error) {
      console.error("error:", error);
      return null;
    }
  };
  static updateAdminNotes = async (depositUuid, adminNotes) => {
    try {
      const response = await depositClient.updateAdminNotes(
        { uuid: depositUuid, adminNotes },
        {}
      );

      if (!response) {
        return null;
      }
      this.insertOrUpdate({ data: response });

      store.dispatch(
        "notifications/showSuccessNotification",
        i18n.t("deposit.adminNotesUpdated")
      );
      return response;
    } catch (error) {
      console.error("error:", error);
      return null;
    }
  };

  static getDraftDepositByCustomerUuid = async (customerUuid) => {
    const draftDepositFromStore = this.query()
      .where("customer", customerUuid)
      .where("status", DEPOSIT_STATUS.DRAFT)
      .withAll()
      .get();

    if (draftDepositFromStore.length) {
      return draftDepositFromStore[0];
    } else {
      try {
        const response = await depositClient.getDraft({ customerUuid }, {});

        if (!response) {
          return null;
        }
        this.insertOrUpdate({ data: response });
        return response;
      } catch (error) {
        // INFO - AM - 07/05/2024 - Deposit can be not found if not already created. Code 5 = Not found
        if (error.code !== 5) {
          console.error("error:", error);
        }
        return null;
      }
    }
  };

  static createOrUpdateDraftDeposit = async (
    form,
    isCustomer = false,
    raiseError = false
  ) => {
    if (!form.depositRecovery) {
      form.depositRecovery = DEPOSIT_RECOVERY.NOT_SET;
    }

    try {
      const response = await depositClient.createOrUpdateDraft(
        DepositRequest.fromJson(form, { ignoreUnknownFields: true }),
        {}
      );

      this.insertOrUpdate({ data: response });
      return response;
    } catch (error) {
      const isSubscriptionUpdateNeeded =
        checkInErrorIfSubscriptionUpdateNeeded(error);
      if (isSubscriptionUpdateNeeded) {
        return null;
      }
      console.error("error:", error);

      if (error.message.includes("UniqueCustomerGenderPerDay")) {
        if (isCustomer) {
          store.dispatch(
            "notifications/showErrorNotification",
            i18n.t("deposit.alreadyExistCustomer")
          );
        } else {
          store.dispatch(
            "notifications/showErrorNotification",
            i18n.t("deposit.alreadyExist")
          );
        }
      } else {
        store.dispatch(
          "notifications/showErrorNotification",
          i18n.t("deposit.errorCreate")
        );
      }
      if (raiseError) {
        throw error;
      }
      return null;
    }
  };
}

export const DEPOSIT_STATUS = {
  DRAFT: "DRAFT",
  WAITING_FOR_VENDOR: "WAITING_FOR_VENDOR",
  CUSTOMER_PREPARING: "CUSTOMER_PREPARING",
  SEND: "SEND",
  RECEIVED: "RECEIVED",
  TODO: "TODO",
  PROGRESS: "PROGRESS",
  CUSTOMER_WAITING: "CUSTOMER_WAITING",
  DEPOSIT_VALIDATED: "DEPOSIT_VALIDATED",
  INLINE: "INLINE",
  RESTITUED: "RESTITUED",
  ABANDONED: "ABANDONED",
};

export const getDefaultStatus = () => {
  return Object.values(DEPOSIT_STATUS).filter((status) => {
    return status !== DEPOSIT_STATUS.ABANDONED;
  });
};

export const excludeAbandonnedAndRestitued = () => {
  return Object.values(DEPOSIT_STATUS).filter((status) => {
    return ![DEPOSIT_STATUS.ABANDONED, DEPOSIT_STATUS.RESTITUED].includes(
      status
    );
  });
};

export const DEPOSIT_GENDER = {
  MAN: "MAN",
  WOMAN: "WOMAN",
  MIXTE: "MIXTE",
  CHILD: "CHILD",
};

export const DEPOSIT_RECOVERY = {
  GET_HOME: "GET_HOME",
  MEETING_POINT: "MEETING_POINT",
  RELAY_POINT: "RELAY_POINT",
  NOT_SET: "NOT_SET",
};

export const DEPOSIT_GAMME = {
  LOW: "LOW",
  AVERAGE: "AVERAGE",
  HIGH: "HIGH", // not used anymore
  HIGH_AND_LUXURY: "HIGH_AND_LUXURY",
  LUXURY: "LUXURY", // Not used anymore
  VINTAGE: "VINTAGE",
  NOT_SET: "NOT_SET",
};

export const SEASON = {
  NOT_SET: "NOT_SET",
  WINTER: "WINTER",
  SPRING: "SPRING",
  SUMMER: "SUMMER",
  AUTUMN: "AUTUMN",
};

export const DEPOSIT_SELL_MODE_STATUS = {
  DEPOSIT_SELL: "DEPOSIT_SELL",
  CASH_OFFER: "CASH_OFFER",
};

export const CASH_OFFER_STATUS = {
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  REFUSED: "REFUSED",
};

export const DEPOSIT_PREPARATION_STATUS = {
  SORTED_AND_SEASONED: "SORTED_AND_SEASONED",
  SORTED_NOT_SEASONED: "SORTED_NOT_SEASONED",
  NOT_SORTED: "NOT_SORTED",
};

export const DEPOSIT_CUSTOMER_VALIDATION = {
  VALIDATED: "VALIDATED",
  REFUSED: "REFUSED",
};
