// @generated by protoc-gen-es v1.7.2 with parameter "target=js+dts"
// @generated from file services/dresskare_back/product.proto (package dresskareback.product, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Struct } from "@bufbuild/protobuf";

/**
 * @generated from message dresskareback.product.BrandListRequest
 */
export const BrandListRequest = proto3.makeMessageType(
  "dresskareback.product.BrandListRequest",
  [],
);

/**
 * @generated from message dresskareback.product.BrandListResponse
 */
export const BrandListResponse = proto3.makeMessageType(
  "dresskareback.product.BrandListResponse",
  () => [
    { no: 1, name: "results", kind: "message", T: BrandResponse, repeated: true },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message dresskareback.product.BrandResponse
 */
export const BrandResponse = proto3.makeMessageType(
  "dresskareback.product.BrandResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "vinted_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.product.BrandRetrieveRequest
 */
export const BrandRetrieveRequest = proto3.makeMessageType(
  "dresskareback.product.BrandRetrieveRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.product.BrandWithScoreResponse
 */
export const BrandWithScoreResponse = proto3.makeMessageType(
  "dresskareback.product.BrandWithScoreResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "vinted_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "score", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.product.ColorListRequest
 */
export const ColorListRequest = proto3.makeMessageType(
  "dresskareback.product.ColorListRequest",
  [],
);

/**
 * @generated from message dresskareback.product.ColorListResponse
 */
export const ColorListResponse = proto3.makeMessageType(
  "dresskareback.product.ColorListResponse",
  () => [
    { no: 1, name: "results", kind: "message", T: ColorResponse, repeated: true },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message dresskareback.product.ColorResponse
 */
export const ColorResponse = proto3.makeMessageType(
  "dresskareback.product.ColorResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "vinted_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "hex_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "order", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "color_text", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.product.ColorRetrieveRequest
 */
export const ColorRetrieveRequest = proto3.makeMessageType(
  "dresskareback.product.ColorRetrieveRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.product.ColorWithScoreResponse
 */
export const ColorWithScoreResponse = proto3.makeMessageType(
  "dresskareback.product.ColorWithScoreResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "vinted_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "hex_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "order", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "score", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 8, name: "color_text", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.product.DepositDestroyRequest
 */
export const DepositDestroyRequest = proto3.makeMessageType(
  "dresskareback.product.DepositDestroyRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.product.DepositGetAdminNotesRequest
 */
export const DepositGetAdminNotesRequest = proto3.makeMessageType(
  "dresskareback.product.DepositGetAdminNotesRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.product.DepositGetAdminNotesResponse
 */
export const DepositGetAdminNotesResponse = proto3.makeMessageType(
  "dresskareback.product.DepositGetAdminNotesResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "admin_notes", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.product.DepositGetDraftRequest
 */
export const DepositGetDraftRequest = proto3.makeMessageType(
  "dresskareback.product.DepositGetDraftRequest",
  () => [
    { no: 1, name: "customer_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.product.DepositGetFilledProductNumberRequest
 */
export const DepositGetFilledProductNumberRequest = proto3.makeMessageType(
  "dresskareback.product.DepositGetFilledProductNumberRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.product.DepositGetFilledProductNumberResponse
 */
export const DepositGetFilledProductNumberResponse = proto3.makeMessageType(
  "dresskareback.product.DepositGetFilledProductNumberResponse",
  () => [
    { no: 1, name: "no_filled_product_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "filled_product_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message dresskareback.product.DepositGetRecommendedPriceRequest
 */
export const DepositGetRecommendedPriceRequest = proto3.makeMessageType(
  "dresskareback.product.DepositGetRecommendedPriceRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.product.DepositGetRecommendedPriceResponse
 */
export const DepositGetRecommendedPriceResponse = proto3.makeMessageType(
  "dresskareback.product.DepositGetRecommendedPriceResponse",
  () => [
    { no: 1, name: "recommended_price", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
);

/**
 * @generated from message dresskareback.product.DepositImageAssignDepositListRequest
 */
export const DepositImageAssignDepositListRequest = proto3.makeMessageType(
  "dresskareback.product.DepositImageAssignDepositListRequest",
  () => [
    { no: 1, name: "results", kind: "message", T: DepositImageAssignDepositRequest, repeated: true },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message dresskareback.product.DepositImageAssignDepositRequest
 */
export const DepositImageAssignDepositRequest = proto3.makeMessageType(
  "dresskareback.product.DepositImageAssignDepositRequest",
  () => [
    { no: 1, name: "deposit", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.product.DepositImageDestroyRequest
 */
export const DepositImageDestroyRequest = proto3.makeMessageType(
  "dresskareback.product.DepositImageDestroyRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.product.DepositImageListRequest
 */
export const DepositImageListRequest = proto3.makeMessageType(
  "dresskareback.product.DepositImageListRequest",
  [],
);

/**
 * @generated from message dresskareback.product.DepositImageListResponse
 */
export const DepositImageListResponse = proto3.makeMessageType(
  "dresskareback.product.DepositImageListResponse",
  () => [
    { no: 1, name: "results", kind: "message", T: DepositImageResponse, repeated: true },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message dresskareback.product.DepositImagePartialUpdateRequest
 */
export const DepositImagePartialUpdateRequest = proto3.makeMessageType(
  "dresskareback.product.DepositImagePartialUpdateRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "deposit", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "image", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "_partial_update_fields", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message dresskareback.product.DepositImageRequest
 */
export const DepositImageRequest = proto3.makeMessageType(
  "dresskareback.product.DepositImageRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "deposit", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "image", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.product.DepositImageResponse
 */
export const DepositImageResponse = proto3.makeMessageType(
  "dresskareback.product.DepositImageResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "deposit", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "image", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.product.DepositImageRetrieveRequest
 */
export const DepositImageRetrieveRequest = proto3.makeMessageType(
  "dresskareback.product.DepositImageRetrieveRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.product.DepositImportDataResponse
 */
export const DepositImportDataResponse = proto3.makeMessageType(
  "dresskareback.product.DepositImportDataResponse",
  () => [
    { no: 1, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "import_result", kind: "message", T: Struct },
  ],
);

/**
 * @generated from message dresskareback.product.DepositPartialUpdateRequest
 */
export const DepositPartialUpdateRequest = proto3.makeMessageType(
  "dresskareback.product.DepositPartialUpdateRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "deposit_datetime", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "vendor", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "customer", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "gender", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "pictures_link", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "company", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 12, name: "default_quality", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 13, name: "_partial_update_fields", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 14, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 15, name: "deposit_recovery", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 16, name: "publied", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 18, name: "gamme", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 19, name: "deposit_end_datetime", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 20, name: "created_by", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 21, name: "default_product_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 22, name: "top_five_brand", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 24, name: "is_okay_to_send_by_post", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 25, name: "seasons", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 26, name: "force_dresskare_commission", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 28, name: "deposit_selling_mode_status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 29, name: "cash_offer_price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 30, name: "luxury_brand", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 31, name: "product_number_range", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 32, name: "categories", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 33, name: "deposit_preparation_status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 34, name: "is_created_by_public_form", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 35, name: "cash_offer_status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 36, name: "customer_validation_response", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 37, name: "customer_validation_comment", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 38, name: "default_product_size", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.product.DepositRequest
 */
export const DepositRequest = proto3.makeMessageType(
  "dresskareback.product.DepositRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "deposit_datetime", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "vendor", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "customer", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "pictures_link", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "company", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "gender", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 12, name: "default_quality", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 14, name: "deposit_recovery", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 15, name: "publied", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 17, name: "gamme", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 18, name: "deposit_end_datetime", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 19, name: "created_by", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 20, name: "default_product_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 21, name: "top_five_brand", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 23, name: "is_okay_to_send_by_post", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 24, name: "seasons", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 25, name: "force_dresskare_commission", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 27, name: "deposit_selling_mode_status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 28, name: "cash_offer_price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 29, name: "luxury_brand", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 30, name: "product_number_range", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 31, name: "categories", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 32, name: "deposit_preparation_status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 33, name: "is_created_by_public_form", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 34, name: "cash_offer_status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 35, name: "customer_validation_response", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 36, name: "customer_validation_comment", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 37, name: "default_product_size", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.product.DepositResponse
 */
export const DepositResponse = proto3.makeMessageType(
  "dresskareback.product.DepositResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "deposit_datetime", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "vendor", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "customer", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "customer_obj", kind: "message", T: SmallCustomerResponse, opt: true },
    { no: 6, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "product_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 8, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "pictures_link", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "company", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 11, name: "is_imported", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 12, name: "gender", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 14, name: "default_quality", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 15, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 16, name: "deposit_recovery", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 20, name: "publied", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 21, name: "gamme", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 22, name: "deposit_end_datetime", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 23, name: "created_by", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 24, name: "top_five_brand", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 26, name: "is_okay_to_send_by_post", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 27, name: "seasons", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 28, name: "default_product_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 29, name: "is_okay_to_send_by_post__is_null", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 30, name: "force_dresskare_commission", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 32, name: "vendor_obj", kind: "message", T: SmallVendorResponse, opt: true },
    { no: 33, name: "deposit_selling_mode_status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 34, name: "cash_offer_price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 35, name: "rate_slides_objs", kind: "message", T: RateSlideResponse, repeated: true },
    { no: 36, name: "luxury_brand", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 39, name: "product_number_range", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 40, name: "categories", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 41, name: "customer_postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 42, name: "deposit_preparation_status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 43, name: "customer_accept_home_take_away", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 44, name: "customer_accept_send_postal", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 45, name: "weight", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 46, name: "customer_prefered_contact_way", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 47, name: "is_created_by_public_form", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 48, name: "exceed_limit", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 49, name: "cash_offer_status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 50, name: "customer_validation_response", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 51, name: "customer_validation_comment", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 52, name: "default_product_size", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 53, name: "images", kind: "message", T: DepositImageResponse, repeated: true },
  ],
);

/**
 * @generated from message dresskareback.product.DepositRetrieveRequest
 */
export const DepositRetrieveRequest = proto3.makeMessageType(
  "dresskareback.product.DepositRetrieveRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.product.DepositUpdateAdminNotesRequest
 */
export const DepositUpdateAdminNotesRequest = proto3.makeMessageType(
  "dresskareback.product.DepositUpdateAdminNotesRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "admin_notes", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.product.DepositWithStatsListRequest
 */
export const DepositWithStatsListRequest = proto3.makeMessageType(
  "dresskareback.product.DepositWithStatsListRequest",
  [],
);

/**
 * @generated from message dresskareback.product.DepositWithStatsListResponse
 */
export const DepositWithStatsListResponse = proto3.makeMessageType(
  "dresskareback.product.DepositWithStatsListResponse",
  () => [
    { no: 1, name: "results", kind: "message", T: DepositWithStatsResponse, repeated: true },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message dresskareback.product.DepositWithStatsResponse
 */
export const DepositWithStatsResponse = proto3.makeMessageType(
  "dresskareback.product.DepositWithStatsResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "deposit_datetime", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "vendor", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "vendor_obj", kind: "message", T: SmallVendorResponse, opt: true },
    { no: 5, name: "customer", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "customer_obj", kind: "message", T: SmallCustomerResponse, opt: true },
    { no: 7, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "gender", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "default_product_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 10, name: "product_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 11, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 12, name: "pictures_link", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 13, name: "company", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 14, name: "is_imported", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 16, name: "default_quality", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 17, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 18, name: "deposit_recovery", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 19, name: "customer_postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 20, name: "publied", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 21, name: "gamme", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 22, name: "deposit_end_datetime", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 23, name: "created_by", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 24, name: "top_five_brand", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 25, name: "luxury_brand", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 26, name: "seasons", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 27, name: "is_okay_to_send_by_post", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 28, name: "is_okay_to_send_by_post__is_null", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 29, name: "force_dresskare_commission", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 30, name: "deposit_preparation_status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 31, name: "deposit_selling_mode_status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 32, name: "cash_offer_price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 33, name: "rate_slides_objs", kind: "message", T: RateSlideResponse, repeated: true },
    { no: 34, name: "product_number_range", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 35, name: "categories", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 36, name: "customer_accept_home_take_away", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 37, name: "customer_accept_send_postal", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 38, name: "weight", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 39, name: "customer_prefered_contact_way", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 40, name: "is_created_by_public_form", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 41, name: "exceed_limit", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 42, name: "month_ca", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 44, name: "pourcent_sell", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 45, name: "vendor_month_ca", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 46, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 47, name: "days_number_between_deposit_assign_and_inline", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 48, name: "cash_offer_status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 49, name: "customer_month_ca", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 50, name: "potential_ca", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 51, name: "customer_validation_response", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 52, name: "customer_validation_comment", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 53, name: "default_product_size", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 54, name: "images", kind: "message", T: DepositImageResponse, repeated: true },
  ],
);

/**
 * @generated from message dresskareback.product.DepositsStatsForFiltersResponse
 */
export const DepositsStatsForFiltersResponse = proto3.makeMessageType(
  "dresskareback.product.DepositsStatsForFiltersResponse",
  () => [
    { no: 1, name: "total_selled_price", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "total_vendor_income", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "stock_refused_or_selled_in_pourcent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "avg_days_number_between_deposit_assign_and_inline", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
);

/**
 * @generated from message dresskareback.product.ImportSheetRequest
 */
export const ImportSheetRequest = proto3.makeMessageType(
  "dresskareback.product.ImportSheetRequest",
  () => [
    { no: 1, name: "sheet_file", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "customer", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "force", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 4, name: "created_by", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "vendor", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "gender", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.product.ItemsToFindPriceProductRequest
 */
export const ItemsToFindPriceProductRequest = proto3.makeMessageType(
  "dresskareback.product.ItemsToFindPriceProductRequest",
  () => [
    { no: 1, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "brand", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "quality", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "deposit", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "vendor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "sub_gender", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.product.PriceForComparableProductResponse
 */
export const PriceForComparableProductResponse = proto3.makeMessageType(
  "dresskareback.product.PriceForComparableProductResponse",
  () => [
    { no: 1, name: "price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "description", kind: "message", T: Struct, opt: true },
  ],
);

/**
 * @generated from message dresskareback.product.ProductCategoryListRequest
 */
export const ProductCategoryListRequest = proto3.makeMessageType(
  "dresskareback.product.ProductCategoryListRequest",
  [],
);

/**
 * @generated from message dresskareback.product.ProductCategoryListResponse
 */
export const ProductCategoryListResponse = proto3.makeMessageType(
  "dresskareback.product.ProductCategoryListResponse",
  () => [
    { no: 1, name: "results", kind: "message", T: ProductCategoryResponse, repeated: true },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message dresskareback.product.ProductCategoryResponse
 */
export const ProductCategoryResponse = proto3.makeMessageType(
  "dresskareback.product.ProductCategoryResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "identifier", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "gender", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "family", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "order", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 7, name: "name_singular", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "family_singular", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "vinted_path_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 10, name: "product_size_group", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 11, name: "package_size", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.product.ProductCategoryRetrieveRequest
 */
export const ProductCategoryRetrieveRequest = proto3.makeMessageType(
  "dresskareback.product.ProductCategoryRetrieveRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.product.ProductDashboardListResponse
 */
export const ProductDashboardListResponse = proto3.makeMessageType(
  "dresskareback.product.ProductDashboardListResponse",
  () => [
    { no: 1, name: "results", kind: "message", T: ProductDashboardResponse, repeated: true },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message dresskareback.product.ProductDashboardResponse
 */
export const ProductDashboardResponse = proto3.makeMessageType(
  "dresskareback.product.ProductDashboardResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "reference", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "deposit", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "sale_status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "suggested_price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 7, name: "selled_price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 8, name: "customer_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "vendor_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "deposit_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.product.ProductDataRecognizedResponse
 */
export const ProductDataRecognizedResponse = proto3.makeMessageType(
  "dresskareback.product.ProductDataRecognizedResponse",
  () => [
    { no: 1, name: "names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "categories", kind: "message", T: ProductCategoryResponse, repeated: true },
    { no: 3, name: "brands", kind: "message", T: BrandWithScoreResponse, repeated: true },
    { no: 4, name: "colors", kind: "message", T: ColorWithScoreResponse, repeated: true },
    { no: 5, name: "sizes", kind: "message", T: ProductSizeWithScoreResponse, repeated: true },
    { no: 6, name: "ia_detection", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message dresskareback.product.ProductDestroyRequest
 */
export const ProductDestroyRequest = proto3.makeMessageType(
  "dresskareback.product.ProductDestroyRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.product.ProductExportListResponse
 */
export const ProductExportListResponse = proto3.makeMessageType(
  "dresskareback.product.ProductExportListResponse",
  () => [
    { no: 1, name: "results", kind: "message", T: ProductExportResponse, repeated: true },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message dresskareback.product.ProductExportResponse
 */
export const ProductExportResponse = proto3.makeMessageType(
  "dresskareback.product.ProductExportResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "reference", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "deposit", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "stock_entry_datetime", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "brand", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "quality", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "suggested_price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 11, name: "selled_price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 12, name: "sell_datetime", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "payed_datetime", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "sale_status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 15, name: "product_links", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 16, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 17, name: "online_datetime", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "sale_status_modified", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "in_invoices", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 21, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 22, name: "is_imported", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 23, name: "vendor_income", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 24, name: "images", kind: "message", T: ProductImageResponse, repeated: true },
    { no: 25, name: "is_price_automatic", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 26, name: "sell_datetime_month", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 27, name: "sale_status_modified_month", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 28, name: "price_depend_on_status", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 29, name: "vendor_income_calculated", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 30, name: "customer_income_calculated", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 31, name: "dresskare_income_calculated", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 32, name: "vendor_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 33, name: "tax_eligible", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 34, name: "customer_income", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 35, name: "dresskare_income", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 36, name: "customer_full_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 37, name: "vendor_full_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 38, name: "sub_gender", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 39, name: "brand_obj", kind: "message", T: BrandResponse, opt: true },
    { no: 40, name: "product_size", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 41, name: "product_size_obj", kind: "message", T: ProductSizeResponse, opt: true },
    { no: 42, name: "notification_sell_sent_to_customer", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 43, name: "colors", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 44, name: "brand_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 45, name: "product_size_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 46, name: "sell_on", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.product.ProductImageDestroyRequest
 */
export const ProductImageDestroyRequest = proto3.makeMessageType(
  "dresskareback.product.ProductImageDestroyRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.product.ProductImageListRequest
 */
export const ProductImageListRequest = proto3.makeMessageType(
  "dresskareback.product.ProductImageListRequest",
  [],
);

/**
 * @generated from message dresskareback.product.ProductImageListResponse
 */
export const ProductImageListResponse = proto3.makeMessageType(
  "dresskareback.product.ProductImageListResponse",
  () => [
    { no: 1, name: "results", kind: "message", T: ProductImageResponse, repeated: true },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message dresskareback.product.ProductImagePartialUpdateRequest
 */
export const ProductImagePartialUpdateRequest = proto3.makeMessageType(
  "dresskareback.product.ProductImagePartialUpdateRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "product", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "image", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "kind", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "_partial_update_fields", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "order", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.product.ProductImageRequest
 */
export const ProductImageRequest = proto3.makeMessageType(
  "dresskareback.product.ProductImageRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "product", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "image", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "kind", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "order", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.product.ProductImageResponse
 */
export const ProductImageResponse = proto3.makeMessageType(
  "dresskareback.product.ProductImageResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "product", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "image", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "kind", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "order", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.product.ProductImageRetrieveRequest
 */
export const ProductImageRetrieveRequest = proto3.makeMessageType(
  "dresskareback.product.ProductImageRetrieveRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.product.ProductLaunchProductRecognitionRequest
 */
export const ProductLaunchProductRecognitionRequest = proto3.makeMessageType(
  "dresskareback.product.ProductLaunchProductRecognitionRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.product.ProductListRequest
 */
export const ProductListRequest = proto3.makeMessageType(
  "dresskareback.product.ProductListRequest",
  [],
);

/**
 * @generated from message dresskareback.product.ProductListResponse
 */
export const ProductListResponse = proto3.makeMessageType(
  "dresskareback.product.ProductListResponse",
  () => [
    { no: 1, name: "results", kind: "message", T: ProductResponse, repeated: true },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message dresskareback.product.ProductPartialUpdateRequest
 */
export const ProductPartialUpdateRequest = proto3.makeMessageType(
  "dresskareback.product.ProductPartialUpdateRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "reference", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "deposit", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "brand", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "quality", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "suggested_price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 10, name: "selled_price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 11, name: "sell_datetime", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "sale_status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 13, name: "product_links", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 14, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 15, name: "online_datetime", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "sale_status_modified", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "is_imported", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 19, name: "vendor_income", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 20, name: "is_price_automatic", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 21, name: "_partial_update_fields", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 22, name: "payed_datetime", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "customer_income", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 24, name: "dresskare_income", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 25, name: "sub_gender", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 26, name: "product_size", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 27, name: "colors", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 28, name: "sell_on", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.product.ProductRequest
 */
export const ProductRequest = proto3.makeMessageType(
  "dresskareback.product.ProductRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "reference", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "deposit", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "brand", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "quality", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "suggested_price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 10, name: "selled_price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 11, name: "sell_datetime", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "sale_status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 13, name: "product_links", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 14, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 15, name: "online_datetime", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "sale_status_modified", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "is_imported", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 19, name: "vendor_income", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 20, name: "is_price_automatic", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 21, name: "payed_datetime", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "customer_income", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 23, name: "dresskare_income", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 24, name: "sub_gender", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 25, name: "product_size", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 26, name: "colors", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 27, name: "sell_on", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.product.ProductResponse
 */
export const ProductResponse = proto3.makeMessageType(
  "dresskareback.product.ProductResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "reference", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "deposit", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "stock_entry_datetime", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "brand", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "quality", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "suggested_price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 11, name: "selled_price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 12, name: "sell_datetime", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "sale_status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 14, name: "product_links", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 15, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 16, name: "online_datetime", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "sale_status_modified", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "in_invoices", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 20, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 21, name: "is_imported", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 22, name: "vendor_income", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 23, name: "images", kind: "message", T: ProductImageResponse, repeated: true },
    { no: 24, name: "is_price_automatic", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 25, name: "payed_datetime", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 26, name: "customer_income", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 27, name: "dresskare_income", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 28, name: "sub_gender", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 29, name: "brand_obj", kind: "message", T: BrandResponse, opt: true },
    { no: 30, name: "product_size", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 31, name: "product_size_obj", kind: "message", T: ProductSizeResponse, opt: true },
    { no: 32, name: "notification_sell_sent_to_customer", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 33, name: "colors", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 34, name: "sell_on", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.product.ProductRetrieveRequest
 */
export const ProductRetrieveRequest = proto3.makeMessageType(
  "dresskareback.product.ProductRetrieveRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.product.ProductSizeListRequest
 */
export const ProductSizeListRequest = proto3.makeMessageType(
  "dresskareback.product.ProductSizeListRequest",
  [],
);

/**
 * @generated from message dresskareback.product.ProductSizeListResponse
 */
export const ProductSizeListResponse = proto3.makeMessageType(
  "dresskareback.product.ProductSizeListResponse",
  () => [
    { no: 1, name: "results", kind: "message", T: ProductSizeResponse, repeated: true },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message dresskareback.product.ProductSizeResponse
 */
export const ProductSizeResponse = proto3.makeMessageType(
  "dresskareback.product.ProductSizeResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "vinted_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "order", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "size_groups", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "group_descriptions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "skip_price_limit", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.product.ProductSizeRetrieveRequest
 */
export const ProductSizeRetrieveRequest = proto3.makeMessageType(
  "dresskareback.product.ProductSizeRetrieveRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message dresskareback.product.ProductSizeWithScoreResponse
 */
export const ProductSizeWithScoreResponse = proto3.makeMessageType(
  "dresskareback.product.ProductSizeWithScoreResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "vinted_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "order", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "size_groups", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "group_descriptions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "score", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 8, name: "skip_price_limit", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.product.RateSlideResponse
 */
export const RateSlideResponse = proto3.makeMessageType(
  "dresskareback.product.RateSlideResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "price_start", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "price_end", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "commission", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 5, name: "dresskare_commission", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "vendor", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "deposit", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "company", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.product.SmallCustomerResponse
 */
export const SmallCustomerResponse = proto3.makeMessageType(
  "dresskareback.product.SmallCustomerResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "is_sourced_by_dresskare", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ],
);

/**
 * @generated from message dresskareback.product.SmallVendorResponse
 */
export const SmallVendorResponse = proto3.makeMessageType(
  "dresskareback.product.SmallVendorResponse",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "vinted_profile", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

